







































































import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
import PolicyComponent from '@/components/PolicyComponent.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import markdownIt from "markdown-it"
import Cropper from 'cropperjs'

@Component({
  components: {
    ClipLoader,
    VueUploadComponent,
    PolicyComponent
  },
})
export default class SettingUser extends Vue {
  isUploading = true
  isUpdate = false
  md = new markdownIt({html: false, breaks: true})
  userInfo: any = {name: '', firstName: '', lastName: '', sex: '', selfIntro: '', fileExist: false, detail: '', date: '', onayamiId: ''}
  registredEmail = ''
  skills: any = {} // safariで動かないためskillsだけ外だし
  categories: any[] = []
  errText = ''
  selfIntroSelectionStart = 0
  cropper: any = ''
  files: any[] = []
  fileErrorMessage = ''
  autoCompletes: {text: string}[] = []

  mounted() {
    let promises = []
    let uid = Common.getUserId()
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('tags').get().then((data) => {
        data.docs.forEach((value, index) => {
          let tag = value.data().tag
          this.autoCompletes.push(tag)
        })
        resolve()
      })
    }))
    

    promises.push(new Promise((resolve) => {
      let categoryPromises = []
      categoryPromises.push(new Promise((categoryResolve) => {
        Common.getCategory().then((categories: any) => {
          let copyCategories = Array.from(categories)
          copyCategories.forEach((value: any) => {
            value.skills = []
          })
          this.categories = copyCategories
          categoryResolve()
        })
      }))

      let skills: any[] = []
      categoryPromises.push(new Promise((categoryResolve) => {
        firebase.firestore().collectionGroup('skills').orderBy('name').get().then((data) => {
          data.docs.forEach((value) => {
            let obj = value.data()!
            obj.categoryId = value.ref.parent.parent!.id
            skills.push(obj)
          })
          categoryResolve()
        })
      }))

      Promise.all(categoryPromises).finally(() => {
        skills.forEach((skill) => {
          this.categories.find((category) => {
            return category.id == skill.categoryId
          }).skills.push(skill)
        })
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      sessionStorage.removeItem('user')
      Common.getUserInfo().then((userInfo) => {
        let userPromises = []
        if (userInfo) {
          this.isUpdate = true
          this.userInfo = userInfo
          //this.skills = this.userInfo.skills ? this.userInfo.skills : {}

          userPromises.push(new Promise((userResolve) => {
            firebase.firestore().collection('users').doc(uid).collection('email').doc(uid).get().then((email) => {
              if (email.exists) {
                this.userInfo.email = email.data()!.email
                this.registredEmail = this.userInfo.emai
              }
            }).finally(() => {
              userResolve()
            })
          }))
        }
        

        Promise.all(userPromises).finally(() => {resolve()})
      })
    }))

    Promise.all(promises).finally(() => {
      for(let categoryKey in this.skills) {
        if (Object.keys(this.skills[categoryKey]).length != 0) {
          this.$root.$emit('bv::toggle::collapse', 'collapse-category' + (Number(categoryKey) - 1))
        }
        for(let skill in this.skills[categoryKey]) {
          for (let i=0; i<this.categories.length;i++) {
            if (this.categories[i].value == categoryKey && !this.categories[i].skills.find((s: any) => {return s.name == skill})) {
              this.categories[i].skills.push({name: skill})
              break
            }
          }
        }
      }
      this.isUploading = false
    })
  }


  create() {
    this.isUploading = true
    let uid = Common.getUserId()
    this.userInfo.onayamiId = this.$route.params.onayamiid
    this.userInfo.fileExist = this.files.length > 0
    this.userInfo.skills = this.skills
    let skillNames: any[] = []
    let categoryValues: any[] = []
    for(let k in this.skills) {
      categoryValues.push(k)
      if(this.skills.hasOwnProperty(k)) {
        for (let skill in this.skills[k]) {
          skillNames.push({level: this.skills[k][skill], name: skill})
        }
      }
    }
    skillNames.sort((a, b) => {
      return b.level - a.level
    })
    skillNames = skillNames.map((value) => {return value.name})
    this.userInfo.createTime = this.userInfo.createTime ? this.userInfo.createTime : firebase.firestore.FieldValue.serverTimestamp()
    let email = this.userInfo.email
    this.userInfo.hasMainRegistered = true;
    firebase.firestore().collection('mainRegistUsers').doc(uid).set(this.userInfo).then(async () => {
      if (this.registredEmail != email) {
        firebase.firestore().collection('mainRegistUsers').doc(uid).collection('email').doc(uid).set({email: email})
      }
      sessionStorage.removeItem('user')
      // 登録後は前画面に戻る。
      history.back();

    }).catch((error: any) => {
      console.error(error)
      this.userInfo.email = email
      this.isUploading = false
      this.errText = '登録に失敗しました'
    })
  }

  skillCheck(value: string, name: string, element: any) {
    if (element.target.checked) {
      if (!this.skills[value]) {
        this.$set(this.skills, value, {})
      }
      this.$set(this.skills[value], name, 1)
    } else {
      this.$delete(this.skills[value], name)
    }
  }

  addSkill(category: any, index: number) {
    if (category.otherSkill) {
      // category.skills.push({name: category.otherSkill})
      this.$set(category.skills, category.skills.length, {name: category.otherSkill})
      this.$set(this.skills[category.value], category.otherSkill, '1')
      this.categories[index].otherSkill = ''
    }
  }

  compiledMarkdownText() {
    return this.userInfo.selfIntro ? this.md.render(this.userInfo.selfIntro) : ''
  }

  detailBlur() {
    this.selfIntroSelectionStart = (<any>document.getElementById('selfIntro')!).selectionStart
  }

  inputFile(newFile: any, oldFile:any) {
    if (newFile.size >= 33554432) {
      this.files = []
      this.fileErrorMessage = 'ファイルの容量は32MBまでです'
      return
    }
    this.fileErrorMessage = ''
  }

  inputFilter(newFile: any, oldFile:any, prevent:any) {
    newFile.url = ''
    let URL = window.URL || (<any>window).webkitURL
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file)
    }
  }

  async updateImageFile(e: any) {
    this.isUploading = true
    let uid = Common.getUserId()
    let file = e.target.files[0]
    var storageRef = firebase.storage().ref()
    let fileName = `${uid}/${Common.createUUID()}${file.name.replace(/.*\./g, '.')}`
    file = await Common.getCompressImageFileAsync(file)
    storageRef.child('users/self/' + fileName).put(file).then(() => {
      let url = Common.createURL(`users/${fileName}`)
      let func = () => {
        this.userInfo.selfIntro = Common.addTextCursor(document.getElementById('selfIntro')!, `![](${url})`)
        this.isUploading = false
      }
      var img = new Image()
      img.src = url
      img.onload = func
      img.onerror = () => {
        setTimeout(() => {
          img.src = url
        }, 500)
      }
    })
  }

}
